const routes = {
  Main: "/main",
  SetFlag: "/main/setflag/group",
  Inspection: "/main/inspection",
  Resolved: "/main/resolved",
  Scan: "/main/remote",
  SelectGroup: "/main/remote/group",
  SelectMachine: "/main/remote/machine",
  BrowseMachines: "/main/remote/group",
  ScanQrCode: "/main/remote/scanQrCode",
  Availability: "/main/availability",
  ManageAvailability: "/main/availability/manage",
  MachineLog: "/main/log",
  MachineBreakdownReport: "/main/breakdownReport",
  MachineDailyLossReport: "/main/dailyLossReport",
  LogOptions: "/main/log/options",

};
export default routes;
