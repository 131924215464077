import styled from "styled-components";

export const Content = styled.div`
  padding: 20px 0 0 0 ;
`;
export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const YellowCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f0ecae;
`;
export const RedCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #d54045;
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;
export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
`;
export const Machine = styled.div`
  width: 130px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.isOrange ? '#FFAC60' : 'black'};
  color: ${props => props.isOrange ? 'black' : 'white'};
  border-radius: 8px;
  margin-right: 20px;
  border: 0.5px solid rgba(186, 186, 186, 0.68);

 .machineCode{
  color: ${props => props.isOrange ? '#363636' : 'white'};
  font-size: 20px;
  border: 2px solid ${props => props.isOrange ? '#363636' : 'white'};
  border-radius: 4px;
  padding: 3px 15px;
 }

 .percent{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: ${props => props.isOrange ? '#363636' : 'white'};
 }
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 83.4%;
  width: 230px;
  color: #ffffff;
  margin-bottom: 15px !important;
`;

export const Average = styled.div`
  width: 150px;
  height: 50px;
  color:#363636;
  display: flex;
  font-size:30px;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-right: 20px;
  margin-left: 20px;
  border: 2px solid #363636;
  font-weight: 800;
  margin-bottom: 10px !important;
`;

export const TableKPI = styled.table`
  width: 90%;

  * { margin: 0 auto; }
  margin-bottom: 15px;
`;