import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NavLink } from "../NavbarStyles";
import { DownloadBtn, Title, ReportInfoForm, ReportLabel, DatePickerDiv, DatePickerBlock, CheckboxInput, ButtonsDiv, DatePickerLabel, ToggleSwitchDiv, ToggleLabel, SwitchInput } from "./Styles";
import { AOO_API_HOST } from "../../../env";

const MachinesDailyLossReport = () => {
  // State for date pickers and checkboxes
  const [startDate, setStartDate] = useState(new Date()); // Default to today's date
  const [endDate, setEndDate] = useState(new Date()); // Default to today's date
  const [reportFormat, setReportFormat] = useState('CSV');  // Set CSV as the default format
  const [reportProportionalCosts, setReportProportionalCosts] = useState('Proportional Costs')
  const navigate = useNavigate();

  // Prepare query parameters (common for both PDF and CSV)
  const prepareQueryParams = () => {
    return {
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString(),
      IsCsv: reportFormat == 'CSV',
      IsProportionalCosts: reportProportionalCosts == 'Proportional Costs',
    };
  };

  // Handle report download (PDF or CSV based on toggle)
  const handleDownloadReport = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      alert('Please select both start and end dates');
      return;
    }

    const params = prepareQueryParams();
    const endpoint = '/aoo-api/Productions/GetMachineDailyLossReport';

    const fileType = reportFormat === 'PDF' ? 'application/pdf' : 'text/csv';
    const fileExtension = reportFormat === 'PDF' ? 'pdf' : 'csv';

    try {
      const response = await axios.post(AOO_API_HOST + endpoint, params,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob',
      }
    );

      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `machine-daily-loss-report-${new Date().toISOString()}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(`Error fetching ${reportFormat} report:`, error);
    }
  };

  return (
    <div>
      <NavLink
        onClick={() => navigate("/main")}
        style={{ marginRight: 30 }}>
        Back to menu
      </NavLink>
      <Title>Machine Daily Loss Report</Title>
      <ReportInfoForm>
        <form>
          {/* Date Pickers */}
          <DatePickerBlock>
            <DatePickerDiv>
                <DatePickerLabel>Start Date: </DatePickerLabel>
                <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="report-datepicker"
                />
            </DatePickerDiv>

            <DatePickerDiv>
                <DatePickerLabel>End Date: </DatePickerLabel>
                <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="report-datepicker"
                />
            </DatePickerDiv>
          </DatePickerBlock>

          {/* Checkboxes */}
          {/* <div>
            <ReportLabel>
              <CheckboxInput
                type="checkbox"
                checked={includeFaulty}
                onChange={(e) => setIncludeFaulty(e.target.checked)}
              />
              Include Faulty
            </ReportLabel>
          </div> */}

          {/* Optional checkboxes */}
          {/* <div style={{ display: 'none' }}>
            <ReportLabel>
              <CheckboxInput
                type="checkbox"
                checked={includeEventDuration}
                onChange={(e) => setIncludeEventDuration(e.target.checked)}
              />
              Include Event Duration
            </ReportLabel>
          </div> */}

          {/* <div>
            <ReportLabel>
              <CheckboxInput
                type="checkbox"
                checked={showEventDurationInDays}
                onChange={(e) => setShowEventDurationInDays(e.target.checked)}
              />
              Show Days Duration Column
            </ReportLabel> 
          </div> */}

          {/* Toggle Switch for PDF/CSV */}
          <ToggleSwitchDiv>
            <ToggleLabel>{reportProportionalCosts}</ToggleLabel>
            <SwitchInput
              type="checkbox"
              checked={reportProportionalCosts === 'Proportional Costs'}
              onChange={(e) => setReportProportionalCosts(e.target.checked ? 'Proportional Costs' : 'Absolute Costs')}
            />
          </ToggleSwitchDiv>
          <ToggleSwitchDiv>
            <ToggleLabel>Download as: {reportFormat}</ToggleLabel>
            <SwitchInput
              type="checkbox"
              checked={reportFormat === 'CSV'}
              onChange={(e) => setReportFormat(e.target.checked ? 'CSV' : 'PDF')}
            />
          </ToggleSwitchDiv>

          {/* Single Button for downloading */}
          <ButtonsDiv>
            <DownloadBtn onClick={handleDownloadReport}>Download {reportFormat}</DownloadBtn>
          </ButtonsDiv>
        </form>
      </ReportInfoForm>
    </div>
  );
};

export default MachinesDailyLossReport;
